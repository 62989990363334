/*
===========================================================
  
  Schedule

===========================================================
*/

/*
-----------------------------------------------------------
  Import
-----------------------------------------------------------
*/

// NPM
import { 
  lightFormat, 
  parseISO, 
  getISODay, 
  startOfWeek, 
  endOfWeek, 
  startOfMonth, 
  addWeeks, 
  addSeconds,
  getStartAndEnd,
} from 'date-fns'

import { RRule, RRuleSet, rrulestr } from 'rrule'

import _ from 'lodash'

/*
-----------------------------------------------------------
  Helper Functions
-----------------------------------------------------------
*/

const getOccurrencesFromTimes = (times) => {

  return []

}

const buildOccurrence = (occurrenceDate, time, rruleInstance) => {

  let occurrence = {}

  occurrence.time = time
  occurrence.rruleInstance = rruleInstance
  
  // X(occurrenceDate)

  occurrence.startDate = occurrenceDate
  occurrence.duration = time.duration
  occurrence.endDate = addSeconds(occurrenceDate, time.duration)


  // X(occurrence.startDate)

  occurrence.startTimeString = lightFormat(occurrence.startDate, 'HH:mm')
  occurrence.weekdayNum = getISODay(occurrence.startDate)
  occurrence.group = []

  return occurrence

}

const processTimes = (times) => {

  let timesSorted = _.orderBy(times, ['start'], ['asc'])

  let occurrencesWithinThisWeek = []

  // We need a five week span so that we have all possible occurrence rules,
  // including "every fifth Thursday" rules.
  let occurrencesWithinFiveWeekSpan = []


  for (let t in timesSorted){

    const time = timesSorted[t]

    const timeRRULE = new rrulestr(time.rrule)

    const date = new Date()

    // X('rrule')
    // X(time.rrule)

    // X('start and end')
    // X(startOfWeek(date))
    // X(endOfWeek(date))


    const occurrenceDatesWithinThisWeek = timeRRULE.between(
      startOfWeek(date), endOfWeek(date)
    )

    // X(occurrenceDatesWithinThisWeek)

    const occurrenceDatesWithinFiveWeekSpan = timeRRULE.between(
      startOfMonth(date), addWeeks(startOfMonth(date), 5)
    )

    for(let occurrenceDate of occurrenceDatesWithinThisWeek){
      // X(occurrenceDate)
      // X('woop')
      occurrencesWithinThisWeek.push(
        buildOccurrence(occurrenceDate, time, timeRRULE)
      )
    }

    for(let occurrenceDateISO of occurrenceDatesWithinFiveWeekSpan){
      occurrencesWithinFiveWeekSpan.push(
        buildOccurrence(occurrenceDateISO, time, timeRRULE)
      )
    }

  }

  return {
    occurrencesWithinThisWeek,
    occurrencesWithinFiveWeekSpan,
  }

}

const groupOverlappingOccurrences = (
  occurrencesWithinThisWeek, occurrencesWithinFiveWeekSpan
) => {

  let occurrencesGrouped = []

  // Loop over all occurrencesWithinThisWeek
  for (let o1 in occurrencesWithinThisWeek){

    // This occurrence will become the main one. 
    // It gets displayed on the schedule.
    let occurrence = occurrencesWithinThisWeek[o1]

    // X('getting overlapping occurrences: ' + occurrence.time.id)

    // Loop over all occurrencesWithinFiveWeekSpan
    for (let o2 in occurrencesWithinFiveWeekSpan){

      const o5w = occurrencesWithinFiveWeekSpan[o2]

      // If it is a different time, but the same start time and weekday, 
      // then add it to the group array.
      if(
        occurrence.time.id != o5w.time.id
        && occurrence.startTimeString == o5w.startTimeString
        && occurrence.weekdayNum == o5w.weekdayNum
      ){
        occurrence.group.push(o5w)
      }
    }
    
    occurrencesGrouped.push(occurrence)

    // X('got overlapping occurrences: ' + occurrence.time.id)

  }

  return occurrencesGrouped

}

const getFilteredOccurrences = (
  weekdayNum, 
  occurrencesWithinThisWeek, 
  pageName = null, 
  stringToFind = null,
) => {
      
  let occurrencesFiltered = []

  if(!occurrencesWithinThisWeek) {
    return []
  }
  
  for(let o in occurrencesWithinThisWeek){
    
    const occurrence = occurrencesWithinThisWeek[o]

    let shouldInclude = false

    // Show all for weekday if displaying weekday.
    if(
      getISODay(occurrence.startDate) == weekdayNum
    ){
      shouldInclude = true
    }
    
    // Show all by default if page is "all".
    if(pageName == 'all'){
      shouldInclude = true
    }
    
    if(
      stringToFind
      && stringToFind != ''
    ){
      shouldInclude = true
    }

    // If using Find, then filter by stringToFind.
    if(
      stringToFind
      && stringToFind != ''
      && occurrence.time.show 
      && !occurrence.time.show.title
        .toLowerCase().includes(stringToFind.toLowerCase())
    ){
      shouldInclude = false
    }

    if(shouldInclude){
      occurrencesFiltered.push(occurrence)
    }

  }
  
  return occurrencesFiltered

}

/*
-----------------------------------------------------------
  Exported Functions
-----------------------------------------------------------
*/

const getOccurrencesForWeekdayFromTimes = (weekdayNum, times) => {
  
  // X(times.data)

  const occurrencesProcessed = processTimes(times)

  const occurrences = occurrencesProcessed.occurrencesWithinThisWeek

  const occurrencesFiltered = getFilteredOccurrences(
    weekdayNum, 
    occurrences,
  )

  if(occurrencesFiltered[0]){
    X(occurrencesFiltered[0].time)
    X(`^ occurrencesFiltered`) 
  }

  return occurrencesFiltered

}

const getISOWeekdays = () => {

  return [
    null,
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ]

}

const getISOWeekday = (ISOWeekdayNum) => {

  return getISOWeekdays()[ISOWeekdayNum]

}

const getWeekdayNumberForDate = (date) => {
  return getISODay(parseISO(date))
}

const getWeekdayNumForToday = () => {
  return getISODay(new Date())
}

const getTimespanString = (start, end) => {

  let val = null
  
  if(start && end){

    const startParsed = parseISO(start)
    const endParsed = parseISO(end)

    val = lightFormat(startParsed, 'h:mma') 
      + ` - `
      + lightFormat(endParsed, 'h:mma')

  }

  return val

}

const getTimespanStringFromActiveSource = (globalPlayerState, studioState) => {

  let start = null
  let end = null
  let val = null

  if(globalPlayerState.hasBroadcast){

    // Try using timespan from Broadcast object first.
    start = _.get(globalPlayerState, `sources.broadcast.start`)
    end = _.get(globalPlayerState, `sources.broadcast.end`)

  }else{

    // Fall back to current for live stream.
    // TOOD: This should only be for studios that have live streams.
    start = _.get(studioState, `current.time.start`)
    end = _.get(studioState, `current.time.end`)

  }

  val = getTimespanString(start, end)

  return val

}

const getTimeString = (time) => {

  let val = null

  const startParsed = parseISO(time.start)
  const endParsed = parseISO(time.end)

  val = getISOWeekday(getISODay(startParsed))
    + ` `
    + lightFormat(startParsed, 'h:mma') 
    + ` - `
    + lightFormat(endParsed, 'h:mma')

  return val

}

/*
-----------------------------------------------------------
  Export
-----------------------------------------------------------
*/

export default {
  getOccurrencesFromTimes,
  getOccurrencesForWeekdayFromTimes,
  getISOWeekdays,
  getWeekdayNumForToday,
  getTimespanString,
  getTimespanStringFromActiveSource,
  getTimeString,
}

