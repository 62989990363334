<template>
  <div class="show-page" v-if="show">

    <div class="image-container">
      <img :src="getImageUrl(show)">
    </div>

    <div class="show-info">
      
      <h1 v-text="show.title"></h1>

      <div v-text="show.description"></div>

      <div class="hosts">
        <div class="host" v-for="user in show.users">
          <router-link 
            :to="'/profiles/' + user.id" 
            v-text="user.name"
          />
        </div>
      </div>

      <div class="times">
        <div 
          class="time" 
          v-for="time in show.times" 
          v-text="getTimeString(time)"
        ></div>
      </div>

    </div>

    <div class="show-broadcasts">
      <CreekBroadcastCard
        v-for="broadcast in broadcasts"
        :broadcast="broadcast"
      />
    </div>

  </div>
</template>

<script>

import CreekStudio from '@creek-website-toolkit/CreekStudio.js'
import CreekBroadcastCard from '@creek-website-toolkit/components/cards/BroadcastCard'
import schedule from '@creek-website-toolkit/lib/schedule.js'

export default {
  name: 'ShowPage',
  data(){
    return {
      show: {},
      broadcasts: [],
    }
  },
  components: {
    CreekBroadcastCard
  },
  props: {
    showName: {
      type: String,
      default: null
    }
  },
  methods: {
    getImageUrl(row){
      let URL = (row.image) ? row.image.url : null
      // let URL = (row.image) ? row.image.url : config.logos.default.URL
      return URL
    },
    getTimeString: schedule.getTimeString,
  },
  async created(){
    this.show = await CreekStudio.get('shows', this.showName)
    X(this.show)
    this.broadcasts = await CreekStudio.get('broadcasts', '?show='+this.show.id)
    // this.broadcasts = await CreekStudio.get('broadcasts')
  }
}
</script>

<style scoped lang="sass">


</style>
